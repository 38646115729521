import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

import { useJsApiLoader } from "@react-google-maps/api";

import { LocationMarkerIcon } from "@heroicons/react/solid";

import HomeHeader from "../home/HomeHeader";
import SearchResults from "./SearchResults";
import { LayoutContext } from "../common/LayoutContext";

import { useWindowSize } from "../../utils/hooks";

import CommunityPage from "../community/CommunityPage";

/**
 * get marker icon with color and index
 * @param {*} color
 * @param {*} index
 */
const getMarkerIcon = (color, favourite, verified, index) => {
    if (color === "purple") {
        if (favourite) {
            //return "/images/markerPurpleHeart40.png";
            return "/images/markerRedHeart40.png";
        } else if (verified) {
            return "/images/markerPurpleDone40.png";
        } else {
            return "/images/markerGray40.png";
            //return "/images/markerPurple40.png";
        }
    } else {
        if (favourite) {
            return "/images/markerGreenHeart40.png";
        } else if (verified) {
            return "/images/markerGreenDone40.png";
        } else {
            return "/images/markerGreen40.png";
        }
    }

    /*if (index) {
        return `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${color}${index}.png`;
    } else {
        return `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_${color}.png`;
    }*/
};

const updateTypes = (types, key, value, first) => {
    let text = "";

    if (types.includes(key)) {
        if (!first) {
            text += "<span> | </span>";
        }

        text += "<span>" + value + "</span>";
    }

    return text;
};

const getCommunityTypes = (facilityType) => {
    let text = "";

    if (facilityType) {
        const types = facilityType.split(";");

        text += updateTypes(types, "IL", "Independent", text === "");
        text += updateTypes(types, "AL", "Assisted", text === "");
        text += updateTypes(types, "MC", "Memory", text === "");
        //text += updateTypes(types, "ALW", "ALW", text === "");
        text += updateTypes(types, "Hospice", "Hospice", text === "");
        text += updateTypes(types, "Respite", "Respite", text === "");
        text += updateTypes(types, "RCFE", "RCFE", text === "");
        text += updateTypes(types, "CCRC", "CCRC", text === "");
        text += updateTypes(types, "NH", "Nursing Home", text === "");
        text += updateTypes(types, "Medicare", "Medicare", text === "");
        text += updateTypes(types, "Medicaid", "ALW/Medi-Cal", text === "");
        text += updateTypes(types, "Medicaid2", "Medicaid", text === "");
        //text += updateTypes(types, "Medi-Cal", "Medi-Cal", text === "");
        text += updateTypes(types, "LTC", "LTC", text === "");
        text += updateTypes(types, "Private Pay", "Private Pay", text === "");
        text += updateTypes(
            types,
            "Managed Care Contracts",
            "Managed Care/Medi-Cal",
            text === ""
        );
        text += updateTypes(
            types,
            "Residential Care Home",
            "Residential Care Home",
            text === ""
        );
        text += updateTypes(types, "IHCS", "In-Home Care Services", text === "");
        text += updateTypes(types, "IHHC", "In-Home Health Care", text === "");
        text += updateTypes(types, "Skilled Nursing", "Skilled Nursing", text === "");
        text += updateTypes(
            types,
            "Rehabilitation Facility",
            "Rehabilitation Facility",
            text === ""
        );
        text += updateTypes(types, "Retirement Living", "Retirement Living", text === "");
        text += updateTypes(types, "Personal Care Home", "Personal Care Home", text === "");
        text += updateTypes(
            types,
            "Board and Care Home",
            "Board and Care Home",
            text === ""
        );
        text += updateTypes(
            types,
            "Adult Foster Care Home",
            "Adult Foster Care Home",
            text === ""
        );
        text += updateTypes(types, "Adult Group Home", "Adult Group Home", text === "");
    }

    return text;
};

// Define a list of libraries to load from the Google Maps API
const libraries = ["places", "drawing", "geometry"];

const SearchPage = ({ search, nameId, community }) => {
    const router = useRouter();
    const { zoom } = useContext(LayoutContext);
    const { favourites, exOut } = useSelector((state) => state.local);
    const [initialHeight, setInitialHeight] = useState(0);
    let { height, width } = useWindowSize();
    const [communities, setCommunities] = useState([]);
    const [googleMap, setGoogleMap] = useState(null);
    const markers = useRef([]);
    const [selectedCommunityId, setSelectedCommunityId] = useState("");
    const fixedGridSizeRef = useRef();
    const [searchMap, setSearchMap] = useState(false);
    const [communitySidebar, setCommunitySidebar] = useState(null);
    const [communityFilter, setCommunityFilter] = useState({
        filterFavourites: false,
        filterTypes: "",
    });

    const onLoad = React.useCallback(function callback(map) {
        setGoogleMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setGoogleMap(null);
    }, []);

    const [mapProps, setMapProps] = useState({
        //center: { lat: 34.1545497, lng: -118.4134366 },
        zoom: 11,
        minZoom: 9,
        mapId: "e4dd67b69e4016f7",
        scaleControl: true,
        onLoad,
        onUnmount,
    });

    const googleMapIsNull = googleMap === null;
    const communitiesJson = JSON.stringify(communities);
    const communityFilterJson = JSON.stringify(communityFilter);

    console.log("search", search, communityFilterJson);

    useEffect(() => {
        if (nameId) {
            setSelectedCommunityId(nameId);
            setCommunitySidebar(nameId);
            //fetchCommunitySidebar(nameId);
        }
    }, [nameId]);

    useEffect(() => {
        // When we first render it on the server useWindowSize() will return undefined!
        // So we need to get the initial Height
        setInitialHeight(window.innerHeight);

        // The only thing that allows to use google map with css zoom
        // is to disable the scrolling on the HTML body
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        return () => {
            // We need to reenable it as we are done with this page
            document.getElementsByTagName("body")[0].style.overflow = "";
        };
    }, []);

    useEffect(() => {
        if (search && googleMap) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                { address: search.replaceAll("-", " ") },
                function (results, status) {
                    if (status === "OK") {
                        googleMap.setCenter(results[0].geometry.location);
                        setMapProps((x) => {
                            return {
                                ...x,
                                center: results[0].geometry.location,
                            };
                        });
                    } else {
                        alert(
                            "Geocode was not successful for the following reason: " + status
                        );
                    }
                }
            );
        }
    }, [search, googleMapIsNull]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`/api/community/city/${search}`);

            const data = await res.json();

            //console.log(data);

            setCommunities(data);

            if (googleMap) {
                addMarkers(googleMap, data, JSON.parse(communityFilterJson));
            }
        };

        fetchData();
    }, [search, communityFilterJson]);

    useEffect(() => {
        if (googleMap) {
            addMarkers(googleMap, communities, JSON.parse(communityFilterJson));
        }
    }, [communitiesJson, googleMapIsNull, communityFilterJson]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY,
        libraries,
    });

    /*useEffect(() => {
        const initAutocomplete = () => {
            const google = window.google;

            if (google === undefined) {
                return;
            }

            const map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: 34.1545497, lng: -118.4134366 },
                zoom: 11,
                minZoom: 9,
                mapId: "e4dd67b69e4016f7",
                //styles: mapStyles,
                //zoomControl: false,
                scaleControl: true,
            });

            //map.setOptions({ minZoom: 5 });

            setInitialHeight(window.innerHeight);

            if (search) {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode(
                    { address: search.replaceAll("-", " ") },
                    function (results, status) {
                        if (status === "OK") {
                            map.setCenter(results[0].geometry.location);
                        } else {
                            alert(
                                "Geocode was not successful for the following reason: " +
                                    status
                            );
                        }
                    }
                );
            }

            setGoogleMap(map);
        };

        // this is needed for testing:
        // wait 1 sec to make sure winodw.google is loaded
        if (window.google !== undefined) {
            initAutocomplete();
        } else {
            setTimeout(initAutocomplete, 1000);
        }
    }, []);*/

    const removeMarkers = () => {
        markers.current.forEach((m) => {
            m.marker.setMap(null);
        });

        markers.current = [];
    };

    const clearMarkers = () => {
        // clear all markers back to purple
        markers.current.forEach((m) => {
            const favourite = favourites && favourites[m.community.nameId];

            m.marker.setIcon(getMarkerIcon("purple", favourite, m.community.onboarded));
        });
    };

    const addMarkers = (googleMap, communities, communityFilter) => {
        removeMarkers();

        const _markers = [];
        let _infoWindow = new google.maps.InfoWindow({
            content: "",
        });

        communities
            .filter((c) => {
                if (communityFilter.filterFavourites) {
                    return favourites && favourites[c.nameId];
                } else if (communityFilter.filterTypes) {
                    const types = c.facilityType ? c.facilityType.split(";") : [];
                    return types.some((t) => communityFilter.filterTypes.includes(t));
                } else {
                    return true;
                }
            })
            .filter((c) => {
                const address = c.contact[0].address[0];
                return address !== undefined && address !== null;
            })
            .forEach((community, index) => {
                const address = community.contact[0].address[0];
                const { lat, lng } = address;

                const favourite = favourites && favourites[community.nameId];

                const marker = new google.maps.Marker({
                    position: { lat, lng },
                    icon: getMarkerIcon("purple", favourite, community.onboarded),
                    map: googleMap,
                    title: community.name,
                });

                marker.addListener("click", () => {
                    clearMarkers();

                    marker.setIcon(getMarkerIcon("green", favourite, community.onboarded));
                    setSelectedCommunityId(community.nameId);

                    /*const e = document.getElementById("id-community-" + community.nameId);
                if (e) {
                    // block: start - didn't work well with map and zoom
                    // block: nearest - worked much better with map and zomm
                    e.scrollIntoView({ behavior: "smooth", block: "nearest" });
                }*/

                    fixedGridSizeRef.current.scrollToCommunity({ community, index });

                    _infoWindow.close();

                    _infoWindow.setContent(`
                        <div style="width: 250px; height: 100px;">
                            <h2 style="font-size: 16px; font-weight: bold;">${
                                community.name
                            }</h2>
                            <div style="margin-top: 4px; text-transform: uppercase; font-weight: bold; color: purple;">
                                ${getCommunityTypes(community.facilityType)}
                            </div>
                        </div>
                    `);

                    _infoWindow.open({
                        anchor: marker,
                        map: googleMap,
                        shouldFocus: false,
                    });

                    //fetchCommunitySidebar(community.nameId);
                });

                _markers.push({ marker, community, index });
            });

        markers.current = _markers;

        google.maps.event.addListener(googleMap, "click", function (event) {
            _infoWindow.close();
            setCommunitySidebar(null);
        });
    };

    // const fetchCommunitySidebar = async (nameId) => {
    //     const res = await fetch(`/api/community/${nameId}`);

    //     const result = await res.json();

    //     setCommunitySidebar(result);
    // };

    const handleSearchMap = async (_searchMap) => {
        setSearchMap(_searchMap);

        if (googleMap && _searchMap) {
            const bounds = googleMap.getBounds();
            console.log(
                "getBounds",
                bounds.getSouthWest().lat(),
                bounds.getNorthEast().lat()
            );

            let geo = bounds.getSouthWest().lng();
            geo += "+" + bounds.getSouthWest().lat();
            geo += "+" + bounds.getNorthEast().lng();
            geo += "+" + bounds.getNorthEast().lat();

            console.log("geo", geo);

            const res = await fetch(`/api/community/geo/${geo}`);

            const data = await res.json();

            //console.log(data);

            setCommunities(data);

            removeMarkers();
            addMarkers(googleMap, data, communityFilter);
        }
    };

    const handleHover = (community) => {
        const marker = markers.current.find((m) => m.community.nameId === community.nameId);
        if (marker) {
            clearMarkers();

            const favourite = favourites && favourites[marker.community.nameId];

            marker.marker.setIcon(
                getMarkerIcon("green", favourite, marker.community.onboarded)
            );
        }
    };

    if (initialHeight === 0) {
        height = 0;
        width = 1000;
    } else {
        if (height === undefined) {
            height = initialHeight / zoom - 64;
        } else {
            height = height / zoom - 64;
        }
    }

    console.log({ height, initialHeight });

    return (
        <div className="">
            <HomeHeader />
            <div className="relative">
                <SearchResults
                    ref={fixedGridSizeRef}
                    search={search}
                    communities={communities}
                    height={height}
                    width={width}
                    selectedCommunityId={selectedCommunityId}
                    onHover={handleHover}
                    searchMap={searchMap}
                    setSearchMap={handleSearchMap}
                    setCommunitySidebar={(c) => {
                        // route to community page
                        router.push(`/${search}/${c.nameId}`);
                    }}
                    setCommunityFilter={setCommunityFilter}
                    isLoaded={isLoaded}
                    mapProps={mapProps}
                />

                {communitySidebar ? (
                    <div
                        style={{
                            position: "absolute",
                            top: 60,
                            right: 0,
                        }}
                        className="w-full sm:w-[1100px] bg-white"
                    >
                        <CommunityPage
                            community={community}
                            sidebar={true}
                            onClose={() => setCommunitySidebar(null)}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default SearchPage;
